import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import axios from '@/axios';
import store from './store/store';
import Vuex from 'vuex'
import VuejsDialog from 'vuejs-dialog';
import router from './routes/routes'
import LightBootstrap from './light-bootstrap-main'
import VueFormWizard from 'vue-form-wizard'
import Vuelidate from 'vuelidate'
import { languages } from './i18n/index.js'
import { defaultLocale } from './i18n/index.js'
import Card from '@/components/Cards/Card.vue'
import LTable from '@/components/Table.vue'
import { ComboBoxPlugin, MultiSelectPlugin  } from "@syncfusion/ej2-vue-dropdowns";
import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { SwitchPlugin } from "@syncfusion/ej2-vue-buttons";
import VueSignaturePad from 'vue-signature-pad';
import VTooltip from 'v-tooltip'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import "../node_modules/@syncfusion/ej2-base/styles/bootstrap.css";
import "../node_modules/@syncfusion/ej2-vue-inputs/styles/bootstrap.css";
import { TreeViewPlugin } from "@syncfusion/ej2-vue-navigations";
import VueEasyLightbox from 'vue-easy-lightbox'

import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);

Vue.use(
  LightBootstrap,
  VueFormWizard,
  );
Vue.use(VTooltip)
Vue.use(VueSignaturePad);
Vue.use(DateRangePickerPlugin);
Vue.use(SwitchPlugin);
Vue.use(VueEasyLightbox)
Vue.component('Card', Card);
Vue.component('LTable', LTable);
Vue.use(NumericTextBoxPlugin);
Vue.use(TreeViewPlugin);

Vue.use(ComboBoxPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(Vuex);
Vue.use(VuejsDialog, {
  okText: 'Aceptar',
  cancelText: 'Cancelar',
  animation: 'bounce',
});
Vue.use(Vuelidate)
Vue.prototype.$axios = axios
Vue.use(VueI18n);
const messages = Object.assign(languages)
let vuex = JSON.parse(localStorage.getItem('vuex'));


var i18n = new VueI18n({
  locale: vuex ? vuex.login.lang : 'es' ,
  fallbackLocale: defaultLocale,
  messages
})


new Vue({
  el: '#app',
  i18n,
  store,
  router,
  render: h => h(App)
})

