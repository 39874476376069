<template>
             <!-- :class="{active : isActive}" -->

  <component :is="baseComponent"
             :to="link.path ? link.path : '/'"
             class="nav-item"
             tag="li">
    <a v-if="isMenu" 
       class="nav-link sidebar-menu-item border-bottom d-flex justify-content-between align-items-center mx-2"
       :aria-expanded="!collapsed"
       data-toggle="collapse"
       :title="link.name"
       @click="collapseMenu">
      <p>
        <i :class="link.icon"></i>
        {{link.name}}
      </p>
      <i v-if="collapsed" class="fas fa-caret-right font16 mr-0"></i>
      <i v-else class="fas fa-caret-down font16 mr-0"></i>
    </a>
    <div v-if="$slots.default || this.isMenu" class="bg-light">
      <!-- <el-collapse-transition> -->
        <ul class="nav" v-show="!collapsed">
          <slot></slot>
        </ul>
      <!-- </el-collapse-transition> -->
    </div>

    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
        <!-- :class="{active: link.active}" -->
      <component
        :to="link.path"
        @click.native="linkClick"
        :is="elementType(link, false)"
        class="py-2 nav-link d-flex align-items-center text-dark  mx-2"
        :target="link.target"
        :title="link.name"
        :href="link.path">
        <template v-if="addLink">
            <i :class="link.icon"></i>
          <span class="sidebar-normal">{{link.name}}</span>
        </template>
        <template v-else>
          <i :class="link.icon"></i>
          <p>{{link.name}}</p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
//   import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  export default {
    name: 'sidebar-item',
    // components: {
    //   [CollapseTransition.name]: CollapseTransition
    // },
    props: {
      menu: {
        type: Boolean,
        default: false
      },
      link: {
        type: Object,
        default: () => {
          return {
            name: '',
            path: '',
            children: []
          }
        }
      }
    },
    provide () {
      return {
        addLink: this.addChild,
        removeLink: this.removeChild
      }
    },
    inject: {
      addLink: {default: null},
      removeLink: {default: null},
      autoClose: {
        default: true
      }
    },
    data ()
    {
      return {
        children: [],
        collapsed: true
      }
    },
    computed: {
      baseComponent ()
      {
        return this.isMenu || this.link.isRoute ? 'li' : 'router-link'
      },
      isMenu ()
      {
        return this.children.length > 0 || this.menu === true
      },
      isActive ()
      {
        if (this.$route && this.$route.path)
        {
          let matchingRoute = this.children.find ((c) => this.$route.path.startsWith (c.link.path))
          if (matchingRoute !== undefined)
          {
            return true
          }
        }
        return false
      }
    },
    methods: {
      addChild (item)
      {
        const index = this.$slots.default.indexOf (item.$vnode)
        this.children.splice (index, 0, item)
      },
      removeChild (item)
      {
        const tabs = this.children
        const index = tabs.indexOf (item)
        tabs.splice (index, 1)
      },
      elementType (link, isParent = true)
      {
        if (link.isRoute === false) return isParent ? 'li' : 'a';
        else return 'router-link';
      },
      linkAbbreviation (name)
      {
        const matches = name.match (/\b(\w)/g)
        return matches.join ('')
      },
      linkClick ()
      {
        if (this.autoClose && this.$sidebar && this.$sidebar.toggleSidebar)
        {
          this.$sidebar.toggleSidebar = false;
        }
      },
      collapseMenu ()
      {
        this.collapsed = !this.collapsed
      },
      collapseSubMenu (link)
      {
        link.collapsed = !link.collapsed
      }
    },
    mounted ()
    {
      if (this.addLink) this.addLink (this);
      if (this.link.collapsed !== undefined)  this.collapsed = this.link.collapsed;
      if (this.isActive && this.isMenu) this.collapsed = false;
    },
    destroyed ()
    {
      if (this.$el && this.$el.parentNode) this.$el.parentNode.removeChild (this.$el);
      if (this.removeLink) this.removeLink (this);
    }
  }
</script>
<style>
  .sidebar-menu-item
  {
    cursor: pointer;
  }
  .font16
  {
    font-size: 16px !important;
  }
</style>
