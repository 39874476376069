import Vue from 'vue';
import Vuex from 'vuex';

import login from './modules/login';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    isLoading: false,
    // adminMenu: true,
    itemData: null,
    headerData: null
  },
  mutations: {
    loadingOn (state) {
      state.isLoading = true
    },
    // adminMenu(state, payload) {
    //   state.adminMenu = payload;
    // },
    loadingOff (state) {
      state.isLoading = false
    },
    storeItemData(state, itemData) {
      state.itemData = itemData
    },
    storeHeaderData(state, headerData) {
      state.headerData = headerData
    }
  },
  actions: {
    isLoadingOn({commit}) {
      commit('loadingOn')
    },
    isLoadingOff({commit}) {
      commit('loadingOff')
    },
    itemData({commit}, itemData) {
      commit('storeItemData', itemData)
    },
    headerData({commit}, headerData) {
      commit('storeHeaderData', headerData)
    },
    // adminMenu({commit}, payload) {
    //   commit('adminMenu', payload);
    // },
  },
  getters: {
    itemData: (state) => {
      return state.itemData;
    },
    headerData: (state) => {
      return state.headerData;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    // adminMenu:(state) => {
    //   return state.adminMenu
    // },
  },
  modules: {
    login
  }
})