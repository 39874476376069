<template>
  <div class="row form-group"
       :class="{
          'input-group-focus': focused,
          'has-error' : validated
       }">
      <div class="col-12">
        <slot name="label">
          <label v-if="label" class="control-label">
            <span v-if="required" class="text-danger">*</span> {{label}}
          </label>
        </slot>
      </div>
      <div class="col-12 px-3">
        <slot>
          <ejs-combobox class="form-control" :allowFiltering='true' :filtering='onFiltering' @change="onChange" v-bind="$attrs" v-model="inputVal" :dataSource='items' v-on="listeners" :fields="fields" :placeholder="placeholder"></ejs-combobox>
        </slot>
      </div>
      <div class="col-12">
        <span class="invalid-feedback">
          <slot name="textError"  ></slot>
        </span>
      </div>
  </div>
</template>
<script>
  import { Query } from '@syncfusion/ej2-data';
  export default {
    inheritAttrs: false,
    name: "base-select",
    props: {
      label: {
        type: String,
        description: "Select label"
      },
      items: {
        type: Array,
      },
      required : {
        type: Boolean,
        default : false
      },
      validated : {
        type : Boolean,
        default : false
      },
      value : [String, Number],
      select_key : {
        type : String,
        default : "id"
      },
       key_option : {
        type : String,
        default : 'name'
      },
      placeholder : {
        type : String,
        default : ""
      }
    },
    model: {
      prop: 'value',
      event: 'select'
    },
    data() {
      return {
        focused: false,
        fields : { text: this.key_option, value: this.select_key },
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          blur: this.onBlur,
          focus: this.onFocus
        }
      },
      inputVal: {
          get: function(){
              return this.value;
          },
          set: function(value){
              this.$emit('select', value)
          }   
      },
    },
    methods: {    
      onFocus() {
        this.focused = true;
      },
      onBlur() {
        this.focused = false;
      },
      onChange(){
        this.$emit('selected');
      },
      onFiltering: function(e) {
        this.query = new Query();
        // frame the query based on search string with filter type.
        this.query = (e.text !== '') ? this.query.where(this.key_option, 'contains', e.text, true) : this.query;
        // pass the filter data source, filter query to updateData method.
        e.updateData(this.items, this.query);
      }
    }
  }
</script>
<style>

.invalid-feedback{
  display: block !important;
}
.has-error .e-input-group{
  border-color: #db2925 !important
}
</style>
