import Sidebar from './SideBar.vue';
import SidebarLink from './SidebarLink.vue';
import SidebarItem from './SidebarItem.vue';

const SidebarStore =
{
  showSidebar: "opened",
  toggleSidebar: false,
  sidebarLinks: [
    {
      name: 'Dashboard',
      icon: 'ti-panel',
      path: '/admin/overview'
    }
  ],
  displaySidebar (value)
  {
    this.showSidebar = value;
    //console.log ("this", this);
    //console.log ("$sidebar", $sidebar);
  }
}

const SidebarPlugin = {

  install (Vue) {
    Vue.mixin({
      data () {
        return {
          sidebarStore: SidebarStore
        }
      }
    })

    Object.defineProperty (Vue.prototype, '$sidebar',
    {
      get ()
      {
        return this.$root.sidebarStore
      }
    })
    Vue.component ('side-bar', Sidebar);
    Vue.component ('sidebar-link', SidebarLink);
    Vue.component ('sidebar-item', SidebarItem);
  }
}

export default SidebarPlugin;
