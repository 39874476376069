<template>
  <div :class="{'mobile-open': $sidebar.toggleSidebar}">
    <div :class="{'nav-open': $sidebar.showSidebar == 'opened' ? true : false, 'nav-reduced': $sidebar.showSidebar == 'reduced' ? true : false, 'nav-closed': $sidebar.showSidebar == 'closed' ? true : false, 'nav-closed-reduced': $sidebar.showSidebar == 'closed_reduced' ? true : false}">
      <loading :active.sync="isLoading" loader="dots" :is-full-page="true" color="#68ACE1"></loading>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import { mapGetters } from 'vuex';
  export default {
         components: {
            Loading
        },
        computed: {
            ...mapGetters({
                isLoading: 'isLoading'
            })
        }
  }
</script>
<style lang="scss">
  .vue-notifyjs.notifications{
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .list-item {
      display: inline-block;
      margin-right: 10px;

    }
    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }
    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }
    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }
  ul.error-list{
      list-style-type: disc;
  }
  .text-sm{
    font-size: 85%;
  }
</style>
