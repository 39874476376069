import axios from 'axios';

axios.default.defaults.baseURL = process.env.VUE_APP_API_URL;

if (localStorage.getItem('vuex')) {
    let vuex = JSON.parse(localStorage.getItem('vuex'))
    axios.defaults.headers.common["Authorization"] = `Bearer ${vuex.login.token}`;
} else {
    axios.defaults.headers.common["Authorization"] = null;
}
export default axios;