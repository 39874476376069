import BaseInput from './components/Inputs/BaseInput.vue'
import MoneyInput from './components/Inputs/MoneyInput.vue'
import BaseCheckbox from './components/Inputs/BaseCheckbox.vue'
import BaseSelect from './components/Inputs/BaseSelect.vue'
import BaseRadio from './components/Inputs/BaseRadio.vue'
import BaseDropdown from './components/BaseDropdown.vue'
import Card from './components/Cards/Card.vue'
import BaseDatepicker from './components/Inputs/BaseDatepicker.vue'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(MoneyInput.name, MoneyInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseSelect.name, BaseSelect)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseDatepicker.name, BaseDatepicker)
    Vue.component('card', Card)
  }
}

export default GlobalComponents
