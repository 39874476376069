import axios from '@/axios';

import router from '../../routes/routes';

const state = {
        auth: null,
        user: null,
        token: null,
        loginStatus: null,
        lang: 'es',
        notifications: [],
        document_notifications: []
      }

const mutations = {
        authUser (state, userData)
        {
          state.token = userData
          state.auth = true
        },
        storeUser (state, user)
        {
          state.user = user
        },
        storeLang (state, lang)
        {
          state.lang = lang
        },
        loginPending (state)
        {
          state.loginStatus = 'PENDING'
        },
        loginError (state)
        {
          state.loginStatus = 'ERROR'
        },
        loginInit (state)
        {
          state.loginStatus = null
        },
        logout (state)
        {
          state.token = null;
          state.user = null;
          state.auth = false;
          state.notification = [];
          state.document_notifications = [];
        },
        notifications (state, notifications)
        {
          state.notifications = notifications;
        },
        document_notifications (state, notifications)
        {
          state.document_notifications = notifications;
        }
      }

const actions = {
        login ({commit}, authData)
        {
          commit ('loginPending')
          axios.post ('/auth/login', {
            email: authData.email,
            password: authData.password,
          })
            .then(res => {
              const now = new Date()
              const expirationDate = new Date(now.getTime() + (3600000 * 24))
              let dataUser = res.data.data;
              localStorage.setItem('token', dataUser.token)
              localStorage.setItem('expirationDate', expirationDate)
              localStorage.setItem('user', JSON.stringify(dataUser))
              commit('authUser', dataUser.token);
              commit('storeUser', dataUser);
              axios.defaults.headers.common["Authorization"] = `Bearer ${dataUser.token}`;
              router.push('/admin/');
              commit('loginInit')
            })
            .catch(() => {
              commit('loginError')
            })
        },
        logout ({commit}) {
          localStorage.removeItem('token')
          localStorage.removeItem('expirationDate')
          localStorage.removeItem('user')
          commit('logout')
          axios.get('/auth/logout')
          router.replace('/login')
        },
        checkStatus({commit}) {
            return new Promise((resolve, reject) => {
                const token = localStorage.getItem('token');
                const user = JSON.parse(localStorage.getItem('user'));
                if(token) {
                  axios.get('/checkstatus', token)
                  .then(() => {
                    commit('authUser', token)
                    commit('storeUser', user)
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  }) 
                } else {
                  reject();
                }
            })
        },
        changeLang ({commit}, lang)
        {
          commit ('storeLang', lang);
        },
        onLoadNotifications ({commit})
        {
          axios ("/request_services?where-status_read=0")
          .then ((response) => {
            commit ('notifications', response.data);
          })
        },
        documentNotifications ({commit})
        {
          axios ("documents_notifications")
          .then ((response) => {
            commit ('document_notifications', response.data);
          })
        }
      }

const getters = {
  auth: (state) => {
    return state.auth;
  },
  user:(state) => {
    return state.user;
  },
  lang :(state) => {
    return state.lang;

  },
  notifications : (state) => {
    return state.notifications;
  },
  document_notifications : (state) => {
    return state.document_notifications;
  },

//   role: (state) => {
//     return state.role;
//   },
  loginStatus:(state) => {
    return state.loginStatus;
  }
}

const namespaced = true;

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}