<template>
  <component :is="tag"
             :to="'#'"
             tag="li">
    <a class="nav-link border-bottom bg-" :class="link.icon == 'fas fa-angle-double-right' ? 'bg-dark':''"
       :title="link.name"
       @click="changeSidebar (link.path)">
      <slot>
        <i v-if="link.icon" :class="link.icon"></i>
        <p>{{link.name}}</p>
      </slot>
    </a>
  </component>
</template>
<script>
  export default {
    name: 'sidebar-link',
    props: {
      link: {
        type: [String, Object],
        default: () => {
          return {
            name: '',
            path: '',
            icon: ''
          }
        }
      },
      tag: {
        type: String,
        default: 'li'
      }
    },
    inject: {
      autoClose: {
        default: true
      }
    },
    methods: {
      changeSidebar (action)
      {
        console.log ("action", action);
        if (this.$sidebar)
        {
          switch (action)
          {
            case "reduceSidebar":
              if (this.$sidebar.showSidebar == "opened") this.$sidebar.displaySidebar ("reduced");
            break;
            case "extendSidebar":
              if (this.$sidebar.showSidebar == "reduced") this.$sidebar.displaySidebar ("opened");
            break;
            case "closeSidebar":
              if (this.$sidebar.showSidebar == "reduced") this.$sidebar.displaySidebar ("closed_reduced");
              else if (this.$sidebar.showSidebar == "opened") this.$sidebar.displaySidebar ("closed");
            break;
            case "openSidebar":             
              if (this.$sidebar.showSidebar == "closed_reduced") this.$sidebar.displaySidebar ("reduced");
              else if (this.$sidebar.showSidebar == "closed") this.$sidebar.displaySidebar ("opened");
            break;
          }
        }
      }
    }
  }
</script>
<style>
</style>
