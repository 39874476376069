<template>
  <div class="sidebar"
       :style="sidebarStyle"
       :data-color="backgroundColor"
       :data-image="backgroundImage">
    <div class="sidebar-wrapper">
      <div class="logo d-lg-none d-block">
        <a href="#" class="simple-text logo__container d-flex justify-content-center">
          <img src="@/assets/images/logo_cleverdocs_blanc.png" alt="" height="55px" width="150px">
        </a>
      </div>

      <slot name="content"></slot>
      <ul class="nav nav-main__links">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link v-for="(link,index) in sidebarLinks"
                        :key="link.name + index"
                        :to="link.path"
                        :href="link.path"
                        @click="closeNavbar"
                        :link="link">
            <i :class="link.icon"></i>
            <p>{{link.name}}</p>
          </sidebar-link>
        </slot>
      </ul>
      <ul class="nav nav-bottom" v-if="$slots['bottom-links']">
        <slot name="bottom-links"></slot>
      </ul>
    </div>
  </div>
</template>
<script>
  import SidebarLink from './SidebarLink.vue'

  export default {
    components: {
      SidebarLink
    },
    props: {
      backgroundColor: {
        type: String,
        default: 'white',
        validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black','white']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String
        // default: require('@/assets/images/sidebar-3.jpg')
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      }
    }
  }

</script>
<style>
  .sidebar .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    background: #212121;
  }
 .sidebar .nav-main__links {
   flex: 1;
   text-align: right;
 }
 .navbar{
    position: fixed !important;
    width: 100%;
    z-index: 99;
 }
  .sidebar .nav .nav-link, body > .navbar-collapse .nav .nav-link{border-bottom: none !important}
  div.sidebar > div > ul.nav.nav-main__links > div > li > div > ul{background: #212121}
  div.sidebar > div > ul.nav.nav-main__links > div > li > div > ul > li > a{color: #fff !important}
  div.sidebar > div > ul.nav.nav-main__links > div > li > div > ul > li > a:hover{background: transparent !important;color: darkgrey !important}
  div.sidebar > div > ul.nav.nav-main__links  div  li >a:hover{background: transparent !important}
  div.sidebar > div > ul.nav.nav-main__links  div  li >a{background: transparent !important}
</style>
