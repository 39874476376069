<template>
  <div class="row form-group"
       :class="{
          'input-group-focus': focused,
          'has-error' : validated
       }">
      <div class="col-12">
        <slot name="label">
          <label v-if="label" class="control-label">
            <span v-if="required" class="text-danger">*</span> {{label}}  
          </label>
        </slot>
      </div>
      
      <div class="col-12">
          <ejs-numerictextbox 
            :value="value"  v-model="inputVal" v-bind="$attrs" currency="EUR"
            v-on="listeners"
            :min="0"
            locale="de"
            :showSpinButton="false"
            class="form-control"
            format="###,###.## €"
            >
          </ejs-numerictextbox>
      </div>
      <div class="col-12">
        <span class="invalid-feedback">
            <slot name="textError"></slot>
        </span>
      </div>
  </div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "money-input",
    props: {
      label: {
        type: String,
        description: "Input label"
      },
      value: {
        type: [String, Number],
        description: "Input value",
        default : 0
      },
      required : {
        type : Boolean,
        default: false
      },
      validated : {
        type : Boolean,
        default : false
      },
   },
    model: {
      prop: 'value',
      event: 'input'
    },
    mounted(){

    },
    data() {
      return {
        focused: false,
      }
    },
    computed: {
      listeners() {
        return {
          ...this.$listeners,
          // change: this.onChange,
        }
      },
      inputVal: {
            get: function(){
                return this.value;
            },
            set: function(value){
                this.$emit('input', value)
            }   
        },
    },
    methods: {
      // onChange(event) {
      //   this.$emit('input', event.value);
      // },
      
    }
  }
</script>
<style>
 .numeric-content-wrapper {
        width: 35%;
        margin: 0 auto;
        min-width: 185px;
    }
    
    .e-float-input.e-numeric.e-input-group {
        margin-top: 40px;
    }
    .numeric-control-label {
        padding: 24px 0px 10px 0px;
        font-size: 12px;
    }
    input.e-input, .e-input-group input, .e-input-group.e-control-wrapper input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
      height: initial !important;
      padding: 8px 12px !important;
    }
</style>
