<template>
  <div class="form-group "
       :class="{
          'input-group-focus': focused
       }">
      <div class="col-12 px-0">
        <slot name="label">
          <label v-if="label" class="control-label">
           <span v-if="required" class="text-danger">*</span> {{label}}
          </label>
        </slot>
      </div>
      <slot>
            <datepicker :value="value" @selected="onChange"   format="dd/MM/yyyy" :calendar-button="true" calendar-button-icon="fa fa-calendar" :language="es"></datepicker>

        <!-- <input
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-control"
          aria-describedby="addon-right addon-left"> -->
      </slot>
      <div class="invalid-feedback">
          <slot name="textError"></slot>
      </div>
      <div class="text-dark textInfo">
          <slot name="textInfo"></slot>
      </div>
  </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    import {es} from 'vuejs-datepicker/dist/locale'

    export default {
        inheritAttrs: false,
        name: "base-datepicker",
        props: {
            required : {
                type : Boolean,
                default : false
            },
            label: {
                type: String,
                description: "Input label"
            },
            value: {
                type: [Date,String],
                description: "Input value"
            },
        },
        components : {
            Datepicker
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                focused: false,
                es : es
            }
        },
     
        computed: {
        listeners() {
            return {
            ...this.$listeners,
            blur: this.onBlur,
            focus: this.onFocus
            }
        }
        },
        methods: {
            onChange(value) {
                this.$emit('input', value)
            },
            onFocus() {
                this.focused = true;
            },
            onBlur() {
                this.focused = false;
            },            
        }
    }
</script>
<style>
  .textInfo{
    font-size: 80%;
    margin-left: 5px;
  }
  .invalid-feedback{
    display: block;
  }
  .vdp-datepicker input {
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
    /* display: block; */
    width: 90%;
  }
.vdp-datepicker__calendar-button{
    width: 10%;
    border: 1px solid #E3E3E3;
    padding: 10px;
    background: #f6f6f6;
}
</style>
