import es from './es.json'
import cat from './cat.json'
import en from './en.json'

export const defaultLocale = 'es'

export const languages = {
    es : es,
    cat: cat,
    en : en
}