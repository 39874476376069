
import Vue from 'vue';
import Router from 'vue-router';
import axios from '@/axios';
Vue.use (
  Router  
);

export const router = new Router
(
  {
    mode: 'history',
    linkActiveClass: 'nav-item active',
    routes:
    [
      { 
        path: '/login', 
        component: () => import('@/pages/auth/Login.vue')
      },
      { 
        path: '/recovery', 
        component: () => import('@/pages/auth/Login.vue')
      },
      {
        path: '/',
        redirect: '/admin'
      },
      {
        path: '/admin',
        beforeEnter: auth,
        component: () => import('@/layout/DashboardLayout.vue'),
        children:
        [
          {
            path : "/",
            component: () => import('@/pages/indexPage.vue')
          },
          // {
          //   path: 'mediaciones',
          //   component: () => import('@/pages/mediations/MediationList.vue')
          // },
          {
            path: 'user',
            component: () => import('@/pages/users/UserSingle.vue'),
            props: true,
            children:
            [
              {
                path: ':id',
                component: () => import('@/pages/users/UserSingle.vue'),
                props: true
              }
            ]
          },
          {
            path: 'users',
            component: () => import('@/pages/users/UserList.vue')
          },
          {
            path: "change_password",
            component: () => import('@/pages/users/changePassword.vue')
          },
          // {
          //   path: 'solicitudes',
          //   component: () => import('@/pages/mediations/SolicitudList.vue')
          // },
          {
            path: 'contacts',
            component: () => import('@/pages/contacts/ContactsList.vue')
          },
          {
            path: 'contact',
            component: () => import('@/pages/contacts/ContactDetail.vue'),
            props: true,
            children:
            [
              {
                path: ':id',
                component: () => import('@/pages/contacts/ContactDetail.vue'),
                props : true,
              }
            ]     
          },      
          {
            path: 'solicitud_doc',
            component: () => import('@/pages/documents/RequestDocumentation.vue'),
          },
          {
            path: 'plantilla_solicitud',
            component: () => import('@/pages/documents/RequestDocumentationTemplate.vue'),
            props : true,
            children : [
              {
                path: ':id',
                component: () => import('@/pages/documents/RequestDocumentationTemplate.vue'),
                props : true,
              }
            ]  
          },
          {
            path: 'solicitud/:id',
            component: () => import('@/pages/mediations/Solicitud.vue'),
            props : true
          },
          {
            path : 'procesar-solicitud/:id',
            component: () => import('@/pages/mediations/ProcesarSolicitud.vue'),
            props : true
          },
          {
            path : 'mediation/:mediation_id',
            component: () => import('@/pages/mediations/ProcesarSolicitud.vue'),
            props : true
          },
          {
            path: 'documents',
            component: () => import('@/pages/documents/documentsList.vue'),
            props: true,
            children:
            [
              {
                path: ':id',
                component: () => import('@/pages/documents/documentsList.vue'),
                props: true
              }
            ]
          },   
          {
            path: 'carpetas',
            component: () => import('@/pages/documents/clientsDocuments.vue'),
            props: true,
            children:
            [
              {
                path: ':id',
                component: () => import('@/pages/documents/clientsDocuments.vue'),
                props: true
              } 
            ]
          },   
          {
            path: 'documentos',
            component: () => import('@/pages/documents/ClientDocumentsList.vue')
          },
          {
            path: 'documents_statistics',
            component: () => import('@/pages/documents/statistics.vue')
          },   
          {
            path: 'ficha_cliente',
            component: () => import('@/pages/clients/clientDetail.vue')
          }/*,
          {
            path: 'ranking_ac',
            component: () => import('@/pages/ranking/RankingAC.vue'),
          },   
          {
            path: 'admin_concursal/:id',
            component: () => import('@/pages/ranking/adminConcursal.vue'),
            props : true,
          },   
          {
            path: 'info_concurso/:id',
            component: () => import('@/pages/ranking/infoConcurso.vue'),
            props : true,
          },   
          {
            path: 'admin_concursal',
            component: () => import('@/pages/ranking/adminConcursalList.vue'),         
          }, 
          {
            path: 'concurso/:admin_id',
            component: () => import('@/pages/ranking/concursoSingle.vue'),  
            props : true,                  
          }, 
          {
            path: 'edit-concurso/:id',
            component: () => import('@/pages/ranking/concursoSingle.vue'),
            props : true,
          },
          {
            path: 'administrador',
            component: () => import('@/pages/ranking/adminConcursalSingle.vue'),
            props : true,
            children : [
              {
                path: ':id',
                component: () => import('@/pages/ranking/adminConcursalSingle.vue'),
                props : true,
              }
            ]
          }*/
        ]
      },
      { 
        path: '*', 
        component: () => import('@/pages/NotFoundPage.vue')
      }
    ]
  }
);

function auth (to, from, next)
{
  let vuex = JSON.parse (localStorage.getItem ('vuex'));
  if (vuex)
  {
    axios.get ('auth/check')
		.then (res =>
    {
			if (res.data.data.token === vuex.login.token)
            {
              if (localStorage.getItem ('path') != null){
                const pathId = localStorage.getItem ('path');
                console.log(localStorage.getItem ('path'));
                localStorage.removeItem ('path');
                next('/admin/documents?path='+pathId)
              }else{
                next()
              }
			}
			else
      {
				if (localStorage.getItem ('vuex'))
        {
					localStorage.removeItem ('vuex');
					location.reload ();
				}
				next ('/login');
			}
		})
		.catch (() =>
    {
			if (localStorage.getItem ('vuex'))
      {
				localStorage.removeItem ('vuex');
				location.reload ();
			}
      next ('/login');
    })
  }
  else
  {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var path = url.searchParams.get("path");
    console.log(path);
    if (path != null) localStorage.setItem ('path', path);
    //console.log(this.$route.query.path);
    next ('/login');
  }
}

// function admin(to, from, next) {
//   let vuex = JSON.parse(localStorage.getItem('vuex'));
//   if(vuex)
//     vuex.login.user.is_administrator == 0 ? next('/home') : next();
//   else
//     next('/login');
  
// }

export default router;
